<template>
    <div class="relative py-24 md:py-32">
        <img src="@/assets/img/fond_noir.jpg" alt="" class="absolute inset-0 -z-10 h-full w-full object-cover" />
        <div class="mx-auto grid max-w-7xl grid-cols-1 gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-5">
            <div class="max-w-2xl xl:col-span-2">
                <h2 class="text-3xl font-bold tracking-tight text-gray sm:text-4xl">{{ $t('our_story.title') }}</h2>
                <p class="mt-6 text-lg leading-8 text-gray">{{ $t('our_story.1') }}</p>
                <p class="mt-6 text-lg leading-8 text-gray">{{ $t('our_story.2') }}</p>
                <p class="mt-6 text-lg leading-8 text-gray">{{ $t('our_story.3') }}</p>
            </div>
            <ul role="list" class="-mt-12 space-y-12 divide-y divide-gray xl:col-span-3">
                <li class="flex flex-col gap-10 pt-12 sm:flex-row">
                    <img class="aspect-[4/5] w-52 flex-none rounded-2xl object-cover" src="@/assets/img/shubham_lakra.jpg"
                        alt="" />
                    <div class="max-w-xl flex-auto">
                        <h3 class="text-lg font-semibold leading-8 tracking-tight text-gray">Shubham Lakra</h3>
                        <ul role="list" class="mt-6 flex gap-x-6">
                            <li>
                                <a href="https://www.linkedin.com/in/shubham-lakra-3263a125/"
                                    class="text-gray hover:text-gray">
                                    <span class="sr-only">LinkedIn</span>
                                    <svg class="h-10 w-10 text-yellow-400" aria-hidden="true" fill="currentColor"
                                        viewBox="0 0 20 20">
                                        <path fill-rule="evenodd"
                                            d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                            clip-rule="evenodd" />
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
                <li class="flex flex-col gap-10 pt-12 sm:flex-row">
                    <img class="aspect-[4/5] w-52 flex-none rounded-2xl object-cover" src="@/assets/img/bredar_pierre.jpg"
                        alt="" />
                    <div class="max-w-xl flex-auto">
                        <h3 class="text-lg font-semibold leading-8 tracking-tight text-gray">Pierre Bredar</h3>
                        <ul role="list" class="mt-6 flex gap-x-6">
                            <li>
                                <a href="https://www.linkedin.com/in/pierre-bredar-2a2694/"
                                    class="text-gray hover:text-gray">
                                    <span class="sr-only">LinkedIn</span>
                                    <svg class="h-10 w-10 text-yellow-400" aria-hidden="true" fill="currentColor"
                                        viewBox="0 0 20 20">
                                        <path fill-rule="evenodd"
                                            d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                            clip-rule="evenodd" />
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
  
<script setup>
</script>