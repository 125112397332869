<template>
    <header class="bg-neutral-900 sticky inset-x-0 top-0 z-50">
        <nav class="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
            <div class="flex items-center gap-x-12">
                <a href="#" class="-m-1.5 p-1.5">
                    <span class="sr-only">Bredlak</span>
                    <img class="w-36" src="@/assets/img/LOGO-BLC-OK.png" alt="" />
                </a>
                <div class="hidden lg:flex lg:gap-x-12">
                    <a v-for="item in navigation" :key="item.name" @click="goto(item.href)"
                        class="text-sm font-semibold leading-6 text-white cursor uppercase">{{ item.name }}</a>
                </div>
            </div>
            <div class="flex lg:hidden">
                <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
                    @click="mobileMenuOpen = true">
                    <span class="sr-only">Open main menu</span>
                    <Bars3Icon class="h-6 w-6" aria-hidden="true" />
                </button>
            </div>
            <div class="hidden lg:flex">
                <img src="@/assets/img/logos/FEDEREC-Adherent.png" class="w-36 mr-6" />
                <img src="@/assets/img/logos/BIR_GoldMembership_logo_RGB.png" class="w-36" />
            </div>
        </nav>
        <Dialog as="div" class="lg:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
            <div class="fixed inset-0 z-10" />
            <DialogPanel
                class="fixed inset-y-0 right-0 mt-28 z-10 w-full overflow-y-auto bg-dark px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                <div class="flow-root">
                    <div class="-my-6 divide-y divide-white/10">
                        <div class="space-y-2 py-6">
                            <a v-for="item in navigation" :key="item.name" @click="goto(item.href, true)"
                                class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-yellow-600 uppercase">
                                {{ item.name }}
                            </a>
                        </div>
                        <div class="py-6">
                            <img src="@/assets/img/logos/FEDEREC-Adherent.png" class="w-40 mb-10" />
                            <img src="@/assets/img/logos/BIR_GoldMembership_logo_RGB.png" class="w-40" />
                        </div>
                    </div>
                </div>
            </DialogPanel>
        </Dialog>
    </header>
</template>

<script setup>
/* eslint-disable */
import { ref, nextTick } from 'vue'
import { Dialog, DialogPanel } from '@headlessui/vue'
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'
import { useI18n } from 'vue-i18n';

const { t } = useI18n()

const navigation = [
    { name: t('menu.services'), href: 'services' },
    { name: t('menu.materials'), href: 'materials' },
    { name: t('menu.whyus'), href: 'whyus' },
    { name: t('menu.our_story'), href: 'our_story' },
    { name: t('menu.contact'), href: 'contact' },
]

const mobileMenuOpen = ref(false)

const goto = (refName, close) => {
    var element = document.getElementById(refName)
    var top = element.offsetTop

    if (close) {
        mobileMenuOpen.value = false
        top = top - 100
    } else {
        top = top - 100
    }

    nextTick(() => {
        window.scrollTo({
            left: 0,
            top: top,
            behavior: "smooth"
        })
    })

}

</script>
