<script setup>
/* eslint-disable */
import ECharts from 'vue-echarts'
import * as echarts from 'echarts'
import { registerMap } from "echarts/core";
import WorldMap from "../utils/world.json"
import { computed } from 'vue';

registerMap("world", WorldMap);

const options = {
    color: "#FEC810",
    backgroundColor: "transparent",
    title: {
        show: false,
    },
    feature: {
        dataZoom: {},
        saveAsImage: {},
        dataView: {},
        magicType: {
            type: ["line", "bar", "stack"],
        },
    },
    visualMap: {
        show: false,
        inRange: {
            symbolSize: [10, 10],
        },
    },
    geo: {
        type: "map",
        map: "world",
        roam: "move",
        zoom: 1.1,
        label: {
            emphasis: {
                show: false,
            },
        },
        itemStyle: {
            normal: {
                areaColor: "#FEC810",
            },
        },
    },
    series: [
        {
            type: "scatter",
            coordinateSystem: "geo",
            data: [
                {
                    name: "Lille FRANCE",
                    value: [3.0635282, 50.6365654],
                    label: {
                        show: true,
                        formatter: "{b}",
                        padding: 5,
                        fontSize: 15,
                        position: [-10, -30],
                        backgroundColor: "#000",
                        color: "#FFF",
                    },
                    itemStyle: {
                        color: "#DB1313",
                    },
                },
                {
                    name: "Zaragoza SPAIN",
                    value: [2.1774299999999585, 41.3829],
                    label: {
                        show: true,
                        formatter: "{b}",
                        padding: 5,
                        fontSize: 15,
                        position: [-50, 15],
                        backgroundColor: "#000",
                        color: "#FFF",
                    },
                    itemStyle: {
                        color: "#2F8BFB",
                    },
                },
                {
                    name: "Atlanta USA",
                    value: [-84.3901849, 33.7490987],
                    label: {
                        show: true,
                        formatter: "{b}",
                        padding: 5,
                        fontSize: 15,
                        position: [-20, -25],
                        backgroundColor: "#000",
                        color: "#FFF",
                    },
                    itemStyle: {
                        color: "#2F8BFB",
                    },
                },
                {
                    name: "New Delhi INDIA",
                    value: [77.20229999999992, 28.6142],
                    label: {
                        show: true,
                        formatter: "{b}",
                        padding: 5,
                        fontSize: 15,
                        position: [0, -25],
                        backgroundColor: "#000",
                        color: "#FFF",
                    },
                    itemStyle: {
                        color: "#2F8BFB",
                    },
                },
            ],
        },
    ],
}

const size = computed(() => {
    const screenWidth = window.innerWidth
    let ret = 200
    if (screenWidth > 1200) {
        ret = 600
    }

    return "height: " + ret + "px";
})
</script>

<template>
    <ECharts ref="barchart" :option="options" class="q-mt-md" :resizable="true" autoresize :style="size" />
</template>