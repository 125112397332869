import Image0LD from "@/assets/img/services/LD/Alu_TT_bredlak_control_aluminium_scrap_.jpg";
import Image0HD from "@/assets/img/services/HD/Alu_TT_bredlak_control_aluminium_scrap_.jpg";
import Image1LD from "@/assets/img/services/LD/bredlak_metal_electronics_wastes_recycling_WEEE.jpg";
import Image1HD from "@/assets/img/services/HD/bredlak_metal_electronics_wastes_recycling_WEEE.jpg";
import Image2LD from "@/assets/img/services/LD/bredlak_team_in_sofia_Bulgaria_eastern_europe.jpg";
import Image2HD from "@/assets/img/services/HD/bredlak_team_in_sofia_Bulgaria_eastern_europe.jpg";
import Image3LD from "@/assets/img/services/LD/Bredlak_quality_check_metal_blocks_SS_304.jpg";
import Image3HD from "@/assets/img/services/HD/Bredlak_quality_check_metal_blocks_SS_304.jpg";
import Image4LD from "@/assets/img/services/LD/bredlak_metal_scrap_balled.jpg";
import Image4HD from "@/assets/img/services/HD/bredlak_metal_scrap_balled.jpg";
import Image5LD from "@/assets/img/services/LD/bredlak_team_sealess_pipe_2nd_grade_steel.jpg";
import Image5HD from "@/assets/img/services/HD/bredlak_team_sealess_pipe_2nd_grade_steel.jpg";
import Image6LD from "@/assets/img/services/LD/Lansdcape_WEEE_scrap_bredlak_metal.jpg";
import Image6HD from "@/assets/img/services/HD/Lansdcape_WEEE_scrap_bredlak_metal.jpg";
import Image7LD from "@/assets/img/services/LD/bredlak_metal_scrap_france_recycling.jpeg";
import Image7HD from "@/assets/img/services/HD/bredlak_metal_scrap_france_recycling.jpeg";
import Image8LD from "@/assets/img/services/LD/landscape_metal_scrap_balled_bredlak.jpg";
import Image8HD from "@/assets/img/services/HD/landscape_metal_scrap_balled_bredlak.jpg";
import Image9LD from "@/assets/img/services/LD/METAL_bulgaria_east_snow_bredlak_hms1_hms2.jpg";
import Image9HD from "@/assets/img/services/HD/METAL_bulgaria_east_snow_bredlak_hms1_hms2.jpg";
import Image10LD from "@/assets/img/services/LD/bredlak_quality_check_field_metal_scrap.jpg";
import Image10HD from "@/assets/img/services/HD/bredlak_quality_check_field_metal_scrap.jpg";
import Image11LD from "@/assets/img/services/LD/pipes_tubes_2nd_second_choice_grade_bredlak.jpg";
import Image11HD from "@/assets/img/services/HD/pipes_tubes_2nd_second_choice_grade_bredlak.jpg";
import Image12LD from "@/assets/img/services/LD/alu_profiles_balled_bredlak.jpg";
import Image12HD from "@/assets/img/services/HD/alu_profiles_balled_bredlak.jpg";
import Image13LD from "@/assets/img/services/LD/bredlak_quality_check_metal_pipes.jpg";
import Image13HD from "@/assets/img/services/HD/bredlak_quality_check_metal_pipes.jpg";
import Image14LD from "@/assets/img/services/LD/radiators_bredlak.jpg";
import Image14HD from "@/assets/img/services/HD/radiators_bredlak.jpg";
import Image15LD from "@/assets/img/services/LD/aluminium_profiles_bales_bredlak_recycling.jpg";
import Image15HD from "@/assets/img/services/HD/aluminium_profiles_bales_bredlak_recycling.jpg";
import Image16LD from "@/assets/img/services/LD/bredlak_quality_control_field_copper_big__bags.jpg";
import Image16HD from "@/assets/img/services/HD/bredlak_quality_control_field_copper_big__bags.jpg";
import Image17LD from "@/assets/img/services/LD/shredded_taint_tabor_aluminium_scrap_bredlak.jpg";
import Image17HD from "@/assets/img/services/HD/shredded_taint_tabor_aluminium_scrap_bredlak.jpg";
import Image18LD from "@/assets/img/services/LD/bredlak_copper_recycling_cuivre.jpeg";
import Image18HD from "@/assets/img/services/HD/bredlak_copper_recycling_cuivre.jpeg";
import Image19LD from "@/assets/img/services/LD/bredlak_taint_tabor.jpg";
import Image19HD from "@/assets/img/services/HD/bredlak_taint_tabor.jpg";
import Image20LD from "@/assets/img/services/LD/wire_rod_off_grade_bredlak_bobines_fil_machine_recyclage.jpg";
import Image20HD from "@/assets/img/services/HD/wire_rod_off_grade_bredlak_bobines_fil_machine_recyclage.jpg";

const imagesLD = [
  Image0LD,
  Image1LD,
  Image2LD,
  Image3LD,
  Image4LD,
  Image5LD,
  Image6LD,
  Image7LD,
  Image8LD,
  Image9LD,
  Image10LD,
  Image11LD,
  Image12LD,
  Image13LD,
  Image14LD,
  Image15LD,
  Image16LD,
  Image17LD,
  Image18LD,
  Image19LD,
  Image20LD,
];

const imagesHD = [
  Image0HD,
  Image1HD,
  Image2HD,
  Image3HD,
  Image4HD,
  Image5HD,
  Image6HD,
  Image7HD,
  Image8HD,
  Image9HD,
  Image10HD,
  Image11HD,
  Image12HD,
  Image13HD,
  Image14HD,
  Image15HD,
  Image16HD,
  Image17HD,
  Image18HD,
  Image19HD,
  Image20HD,
];

export { imagesHD, imagesLD };
