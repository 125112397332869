<template>
  <div class="bg-gradient-to-b from-neutral-900 to-slate-900 isolate overflow-hidden pt-0">
    <!-- <img src="@/assets/img/fond_noir.jpg" alt="" class="absolute inset-0 -z-10 h-full w-full object-cover" /> -->
    <div class="mx-auto max-w-2xl py-20 sm:py-48 lg:py-42">
      <div class="text-center">
        <h4 class="font-bold tracking-tight text-gray text-4xl">
          {{ $t('hero.title1') }}<br />
          {{ $t('hero.title2') }}<br />
          {{ $t('hero.title3') }}<br />
          {{ $t('hero.title4') }}
        </h4>
        <img class="mt-6 mx-auto" src="@/assets/img/hd_logo_bredlack_text.png" alt="Logo" style="width: 300px" />
      </div>
    </div>
    <div
      class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
      aria-hidden="true">
      <div
        class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
        style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
    </div>
  </div>
</template>
  
<script setup>
</script>