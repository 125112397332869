<template>
    <div class="relative isolate py-24 sm:py-24">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
            <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
                <h2 class="text-center text-4xl font-semibold leading-8 text-gray-900">
                    {{ $t('partners.title') }}
                </h2>
                <div
                    class="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-4">
                    <img class="col-span-2 w-full object-contain lg:col-span-1" src="@/assets/img/logos/business_france.jpg"
                        width="158" />
                    <img class="col-span-2 w-full object-contain lg:col-span-1" src="@/assets/img/logos/CCI_lille.png"
                        width="220" />
                    <img class="col-span-2 w-full object-contain lg:col-span-1"
                        src="@/assets/img/logos/logo_neo_eco_0_0.webp" width="300" />
                    <img class="col-span-2 w-full object-contain sm:col-start-2 lg:col-span-1"
                        src="@/assets/img/logos/inoffplastic.png" width="180" />
                </div>
                <div
                    class="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-4">
                    <img class="col-span-2 col-start-2 w-full object-contain sm:col-start-auto lg:col-span-1"
                        src="@/assets/img/logos/hdf.jpg" width="220" />
                    <img class="col-span-2 w-full object-contain lg:col-span-1"
                        src="@/assets/img/logos/prefecture_nord.jpeg" width="230" />
                    <img class="col-span-2 w-full object-contain lg:col-span-1" src="@/assets/img/logos/logo-edhec.png"
                        width="300" />
                    <img class="col-span-2 w-full object-contain lg:col-span-1" src="@/assets/img/logos/ademe-logo.jpg"
                        width="150" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import BusinessFrance from "@/assets/img/logos/business_france.jpg"
import CCILille from "@/assets/img/logos/CCI_lille.png"
import NeoEco from "@/assets/img/logos/logo_neo_eco_0_0.png"
import InOffPlastic from "@/assets/img/logos/inoffplastic.png"
import HDF from "@/assets/img/logos/hdf.jpg"
import PrefectureNord from "@/assets/img/logos/prefecture_nord.jpg"
import LogoEdhec from "@/assets/img/logos/logo-edhec.png"
import Ademe from "@/assets/img/logos/ademe-logo.jpg"
import { ref } from 'vue'

const logos = [
    { "url": "https://www.businessfrance.fr/", "logo": BusinessFrance },
    { "url": "https://hautsdefrance.cci.fr/cci-grand-lille/", "logo": CCILille },
    { "url": "https://neo-eco.fr", "logo": NeoEco },
    { "url": "https://inoffplastic.com", "logo": InOffPlastic },
    { "url": "https://hautsdefrance.fr", "logo": HDF },
    { "url": "http://www.nord.gouv.fr/", "logo": PrefectureNord },
    { "url": "https://www.edhec.edu/", "logo": LogoEdhec },
    { "url": "https://www.ademe.fr/", "logo": Ademe }
]

const breakpoints = ref({
    // 700px and up
    700: {
        itemsToShow: 1,
        snapAlign: 'center',
    },
    // 1024 and up
    1024: {
        itemsToShow: 4,
        snapAlign: 'start',
    },
})

</script>

<style>
.img-wrapper {
    /* z-index: 4;
    opacity: 1;
    background-color: rgb(240, 240, 240);
    border-width: 1px;
    border-style: solid;
    border-color: rgb(240, 240, 240);
    width: 300px;
    height: 300px;
    padding: 5px;
    margin: 10px; */
}

.img-wrapper img {
    /* width: 300px; */
    /* position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 100%; */
}
</style>