import { createApp } from "vue";
import App from "./App.vue";
import i18n from "./plugins/i18n";

import "./assets/index.css";
import ScrollParallax from "vue3-parallax/src/components/ScrollParallax.vue";

const app = createApp(App);
app.component("scroll-parallax", ScrollParallax);

app.use(i18n);

app.mount("#app");
