<template>
    <carousel ref="serviceCarousel" :items-to-show="nb_images()" :itemsToScroll="1" :wrap-around="true" :autoplay="4000"
        :transition="500" :breakpoints="breakpoints">
        <slide v-for="(image, index) in  imagesLD " :key="`materials_${index}`">
            <div class="img-wrapper">
                <img :src="image" @click="open(index)" class="cursor" />
            </div>
        </slide>

        <template #addons>
            <navigation />
        </template>
    </carousel>
    <FsLightbox :toggler="toggler" :sources="imagesHD" :slide="index" :onClose="close" />
</template>
  

<script setup>
/* eslint-disable */
import FsLightbox from "fslightbox-vue/v3";

import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'

import { ref } from 'vue'
import { imagesLD, imagesHD } from "@/utils/serviceImages"

const serviceCarousel = ref(null)
const toggler = ref(false)
const index = ref(null)

const breakpoints = ref({
    // 700px and up
    700: {
        itemsToShow: 1,
        snapAlign: 'center',
    },
    // 1024 and up
    1024: {
        itemsToShow: 4,
        snapAlign: 'start',
    },
})

const nb_images = () => {
    const screenWidth = window.innerWidth
    let ret = 1
    if (screenWidth > 1200) {
        ret = 4
    } else if (screenWidth > 600) {
        ret = 2
    }

    return ret
}

const open = (i) => {
    index.value = i + 1
    toggler.value = !toggler.value
}

const close = () => {
    index.value = null
}
</script>

<style>
.img-wrapper {
    z-index: 4;
    opacity: 1;
    background-color: rgb(240, 240, 240);
    border-width: 1px;
    border-style: solid;
    border-color: rgb(240, 240, 240);
    width: 300px;
    height: 300px;
    padding: 5px;
    margin: 10px;
}

.img-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 100%; */
}
</style>@/utils/materialImages