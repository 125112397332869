<template>
    <button type="button"
        class="inline-flex items-center gap-x-2 rounded-md bg-yellow-400 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-yellow-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
        @click="goto('contact')">
        <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
        {{ $t('button.contact_us') }}
    </button>
</template>
<script setup>
import { CheckCircleIcon } from '@heroicons/vue/20/solid'

const goto = (refName) => {
    var element = document.getElementById(refName)
    var top = element.offsetTop

    window.scrollTo({
        left: 0,
        top: top - 100,
        behavior: "smooth"
    })
}
</script>