import Image0LD from "@/assets/img/materials/LD/cars_wire_harnesses_bredlak_automotive.jpg";
import Image0HD from "@/assets/img/materials/HD/cars_wire_harnesses_bredlak_automotive.jpg";
import Image1LD from "@/assets/img/materials/LD/high_carbon_galvanized_and_bride_Wires_second_grade_2nd_bredlak.jpg";
import Image1HD from "@/assets/img/materials/HD/high_carbon_galvanized_and_bride_Wires_second_grade_2nd_bredlak.jpg";
import Image2LD from "@/assets/img/materials/LD/IMAGE_HD_page_garde_brass_metal_scrap_bronze_bredlak_recycling_laiton.jpg";
import Image2HD from "@/assets/img/materials/HD/IMAGE_HD_page_garde_brass_metal_scrap_bronze_bredlak_recycling_laiton.jpg";
import Image3LD from "@/assets/img/materials/LD/Alu_profiles_painted_bredlak.jpg";
import Image3HD from "@/assets/img/materials/HD/Alu_profiles_painted_bredlak.jpg";
import Image4LD from "@/assets/img/materials/LD/UBC_aluminium_used_beverage_cans_bredlak.jpg";
import Image4HD from "@/assets/img/materials/HD/UBC_aluminium_used_beverage_cans_bredlak.jpg";
import Image5LD from "@/assets/img/materials/LD/bredlak_metal_alu_rims__france.jpg";
import Image5HD from "@/assets/img/materials/HD/bredlak_metal_alu_rims__france.jpg";
import Image6LD from "@/assets/img/materials/LD/Alu_rims_bredlak.jpg";
import Image6HD from "@/assets/img/materials/HD/Alu_rims_bredlak.jpg";
import Image7LD from "@/assets/img/materials/LD/Taint_Tabor_bredlak.jpg";
import Image7HD from "@/assets/img/materials/HD/Taint_Tabor_bredlak.jpg";
import Image8LD from "@/assets/img/materials/LD/metal_scrap_recuperation_bredlak_balles.jpg";
import Image8HD from "@/assets/img/materials/HD/metal_scrap_recuperation_bredlak_balles.jpg";
import Image9LD from "@/assets/img/materials/LD/bredlak_recycling_laiton_brass_scrap_metal.jpg";
import Image9HD from "@/assets/img/materials/HD/bredlak_recycling_laiton_brass_scrap_metal.jpg";
import Image10LD from "@/assets/img/materials/LD/aluminium_scrap_bredlak_metal.jpg";
import Image10HD from "@/assets/img/materials/HD/aluminium_scrap_bredlak_metal.jpg";
import Image11LD from "@/assets/img/materials/LD/gonzalo_cardboard_carton_occ_bredlak_.jpg";
import Image11HD from "@/assets/img/materials/HD/gonzalo_cardboard_carton_occ_bredlak_.jpg";
import Image12LD from "@/assets/img/materials/LD/electric_motors_scrap_alternators_bredlak.jpg";
import Image12HD from "@/assets/img/materials/HD/electric_motors_scrap_alternators_bredlak.jpg";
import Image13LD from "@/assets/img/materials/LD/alu_bottle_caps_capsules_balles_bredlak.jpg";
import Image13HD from "@/assets/img/materials/HD/alu_bottle_caps_capsules_balles_bredlak.jpg";
import Image14LD from "@/assets/img/materials/LD/Alu_profiles_bredlak.jpg";
import Image14HD from "@/assets/img/materials/HD/Alu_profiles_bredlak.jpg";
import Image15LD from "@/assets/img/materials/LD/Tally_radiators_bredlak.jpg";
import Image15HD from "@/assets/img/materials/HD/Tally_radiators_bredlak.jpg";
import Image16LD from "@/assets/img/materials/LD/Cast_iron_bredlak__field.jpg";
import Image16HD from "@/assets/img/materials/HD/Cast_iron_bredlak__field.jpg";
import Image17LD from "@/assets/img/materials/LD/welded_tubes_and_pipes_bredlak_second_grade_seamless.jpg";
import Image17HD from "@/assets/img/materials/HD/welded_tubes_and_pipes_bredlak_second_grade_seamless.jpg";
import Image18LD from "@/assets/img/materials/LD/gonzalo_France_bredlak_dripping_irrigation_pipes_PE_agricole_bales.jpg";
import Image18HD from "@/assets/img/materials/HD/gonzalo_France_bredlak_dripping_irrigation_pipes_PE_agricole_bales.jpg";
import Image19LD from "@/assets/img/materials/LD/bredlak_quality_check_hms_metal_scrap.jpg";
import Image19HD from "@/assets/img/materials/HD/bredlak_quality_check_hms_metal_scrap.jpg";
import Image20LD from "@/assets/img/materials/LD/gonzalo_pierre_France_LDPE_jazz_mixed_PE_bales_PEBD_Bredlak_.jpg";
import Image20HD from "@/assets/img/materials/HD/gonzalo_pierre_France_LDPE_jazz_mixed_PE_bales_PEBD_Bredlak_.jpg";
import Image21LD from "@/assets/img/materials/LD/aluminium_radiators_bredlak_bulk.jpg";
import Image21HD from "@/assets/img/materials/HD/aluminium_radiators_bredlak_bulk.jpg";
import Image22LD from "@/assets/img/materials/LD/picture_of_metal_discs_iron_cast_rims_fonte_bredlak.jpg";
import Image22HD from "@/assets/img/materials/HD/picture_of_metal_discs_iron_cast_rims_fonte_bredlak.jpg";
import Image23LD from "@/assets/img/materials/LD/galva_wires_coils_bredlak_galvanized.jpg";
import Image23HD from "@/assets/img/materials/HD/galva_wires_coils_bredlak_galvanized.jpg";
import Image24LD from "@/assets/img/materials/LD/millberry_copper_bredlak.jpg";
import Image24HD from "@/assets/img/materials/HD/millberry_copper_bredlak.jpg";
import Image25LD from "@/assets/img/materials/LD/gonzalo_Bredlak_france_ldpe_pebd_balles_plastique_recyclable.jpg";
import Image25HD from "@/assets/img/materials/HD/gonzalo_Bredlak_france_ldpe_pebd_balles_plastique_recyclable.jpg";
import Image26LD from "@/assets/img/materials/LD/IMAGE_BD_page_garde_metal_BREDLAK_brass_bronze.jpg";
import Image26HD from "@/assets/img/materials/HD/IMAGE_BD_page_garde_metal_BREDLAK_brass_bronze.jpg";
import Image27LD from "@/assets/img/materials/LD/aluminium_profiles_painted_bredlak.jpg";
import Image27HD from "@/assets/img/materials/HD/aluminium_profiles_painted_bredlak.jpg";
import Image28LD from "@/assets/img/materials/LD/galvanized__wires_coils_bredlak_galva.jpg";
import Image28HD from "@/assets/img/materials/HD/galvanized__wires_coils_bredlak_galva.jpg";
import Image29LD from "@/assets/img/materials/LD/bredlak_aluminium_copper_radiators_scrap_.jpg";
import Image29HD from "@/assets/img/materials/HD/bredlak_aluminium_copper_radiators_scrap_.jpg";
import Image30LD from "@/assets/img/materials/LD/bredlak_motor_alternator_scrap_metal.jpg";
import Image30HD from "@/assets/img/materials/HD/bredlak_motor_alternator_scrap_metal.jpg";
import Image31LD from "@/assets/img/materials/LD/Brass_coated_steelcord_second_grade_bredlak.jpg";
import Image31HD from "@/assets/img/materials/HD/Brass_coated_steelcord_second_grade_bredlak.jpg";
import Image32LD from "@/assets/img/materials/LD/scrap_metal_bredlak_recycling_france.jpg";
import Image32HD from "@/assets/img/materials/HD/scrap_metal_bredlak_recycling_france.jpg";
import Image33LD from "@/assets/img/materials/LD/gonzalo_plastic_cardboard__carton_balles_PEBD_ldpe.jpg";
import Image33HD from "@/assets/img/materials/HD/gonzalo_plastic_cardboard__carton_balles_PEBD_ldpe.jpg";
import Image34LD from "@/assets/img/materials/LD/gonzalo_rolls_plastic_spain_bredlak.jpg";
import Image34HD from "@/assets/img/materials/HD/gonzalo_rolls_plastic_spain_bredlak.jpg";
import Image35LD from "@/assets/img/materials/LD/gonzalo_pierre_FRANCE_bredlak_recycling_ldpe_pebd_films_balles.jpg";
import Image35HD from "@/assets/img/materials/HD/gonzalo_pierre_FRANCE_bredlak_recycling_ldpe_pebd_films_balles.jpg";

const imagesHD = [
  Image0HD,
  Image1HD,
  Image2HD,
  Image3HD,
  Image4HD,
  Image5HD,
  Image6HD,
  Image7HD,
  Image8HD,
  Image9HD,
  Image10HD,
  Image11HD,
  Image12HD,
  Image13HD,
  Image14HD,
  Image15HD,
  Image16HD,
  Image17HD,
  Image18HD,
  Image19HD,
  Image20HD,
  Image21HD,
  Image22HD,
  Image23HD,
  Image24HD,
  Image25HD,
  Image26HD,
  Image27HD,
  Image28HD,
  Image29HD,
  Image30HD,
  Image31HD,
  Image32HD,
  Image33HD,
  Image34HD,
  Image35HD,
];

const imagesLD = [
  Image0LD,
  Image1LD,
  Image2LD,
  Image3LD,
  Image4LD,
  Image5LD,
  Image6LD,
  Image7LD,
  Image8LD,
  Image9LD,
  Image10LD,
  Image11LD,
  Image12LD,
  Image13LD,
  Image14LD,
  Image15LD,
  Image16LD,
  Image17LD,
  Image18LD,
  Image19LD,
  Image20LD,
  Image21LD,
  Image22LD,
  Image23LD,
  Image24LD,
  Image25LD,
  Image26LD,
  Image27LD,
  Image28LD,
  Image29LD,
  Image30LD,
  Image31LD,
  Image32LD,
  Image33LD,
  Image34LD,
  Image35LD,
];

export { imagesHD, imagesLD };
