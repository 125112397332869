<template>
    <div class="relative isolate py-12 sm:py-12">
        <img src="@/assets/img/fond_noir.jpg" alt="" class="absolute inset-0 -z-10 h-full w-full object-cover" />

        <div class="mx-auto max-w-7xl px-6 lg:px-8">
            <div class="mx-auto max-w-2xl text-center">
                <p class="mt-2 text-4xl font-bold text-gray sm:text-4xl uppercase">
                    {{ $t('services.title') }}
                </p>
                <p class="mt-6 text-3xl leading-8 text-gray">
                    {{ $t('services.leading1') }}
                </p>
                <p class="mt-6 text-3xl leading-8 text-gray">
                    {{ $t('services.leading2') }}
                </p>
            </div>

            <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
                <dl class="grid max-w-xl grid-cols-1 gap-x-0 gap-y-16 lg:max-w-none lg:grid-cols-2">
                    <div class="flex flex-col">
                        <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-white">
                            <span style="width: 150px; height: 150px"
                                class="inline-flex mx-auto items-center text-center justify-center rounded-full ring-4 ring-orange-500">
                                <span class="text-3xl font-medium leading-none text-gray uppercase">
                                    {{ $t('services.when_you_sell') }}
                                </span>
                            </span>
                        </dt>
                        <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-300">
                            <ul role="list" class="list-disc text-gray py-2 px-6 text-left text-md lg:ml-24">
                                <li class="mb-2">{{ $t('services.sell.1') }}</li>
                                <li class="mb-2">{{ $t('services.sell.2') }}</li>
                                <li class="mb-2">{{ $t('services.sell.3') }}</li>
                                <li class="mb-2">{{ $t('services.sell.4') }}</li>
                                <li class="mb-2">{{ $t('services.sell.5') }}</li>
                            </ul>
                        </dd>
                    </div>
                    <div class="flex flex-col">
                        <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-white">
                            <span style="width: 150px; height: 150px"
                                class="inline-flex mx-auto items-center text-center justify-center rounded-full ring-4 ring-purple-500">
                                <span class="text-3xl font-medium leading-none text-gray uppercase">
                                    {{ $t('services.when_you_buy') }}
                                </span>
                            </span>
                        </dt>
                        <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-300">
                            <ul role="list" class="list-disc text-gray py-2 px-6 text-left text-md lg:ml-24">
                                <li class="mb-2">{{ $t('services.buy.1') }}</li>
                                <li class="mb-2">{{ $t('services.buy.2') }}</li>
                                <li class="mb-2">{{ $t('services.buy.3') }}</li>
                                <li class="mb-2">{{ $t('services.buy.4') }}</li>
                                <li class="mb-2">{{ $t('services.buy.5') }}</li>
                            </ul>
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
    </div>
</template>
  
<script setup>
</script>