<template>
    <div class="relative isolate bg-gray-900">
        <img src="@/assets/img/map-image.png" alt="" class="absolute inset-0 -z-10 h-full w-full object-cover" />
        <div class="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
            <div class="relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-20 lg:py-48">
                <div class="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                    <div class="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden ring-1 ring-white/5 lg:w-1/2">
                        <svg class="absolute inset-0 h-full w-full stroke-gray-700 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                            aria-hidden="true">
                            <defs>
                                <pattern id="54f88622-e7f8-4f1d-aaf9-c2f5e46dd1f2" width="200" height="200" x="100%" y="-1"
                                    patternUnits="userSpaceOnUse">
                                    <path d="M130 200V.5M.5 .5H200" fill="none" />
                                </pattern>
                            </defs>
                            <svg x="100%" y="-1" class="overflow-visible fill-gray-800/20">
                                <path d="M-470.5 0h201v201h-201Z" stroke-width="0" />
                            </svg>
                            <rect width="100%" height="100%" stroke-width="0"
                                fill="url(#54f88622-e7f8-4f1d-aaf9-c2f5e46dd1f2)" />
                        </svg>
                        <div class="absolute -left-56 top-[calc(100%-13rem)] transform-gpu blur-3xl lg:left-[max(-14rem,calc(100%-59rem))] lg:top-[calc(50%-7rem)]"
                            aria-hidden="true">
                            <div class="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-br from-[#80caff] to-[#4f46e5] opacity-20"
                                style="clip-path: polygon(74.1% 56.1%, 100% 38.6%, 97.5% 73.3%, 85.5% 100%, 80.7% 98.2%, 72.5% 67.7%, 60.2% 37.8%, 52.4% 32.2%, 47.5% 41.9%, 45.2% 65.8%, 27.5% 23.5%, 0.1% 35.4%, 17.9% 0.1%, 27.6% 23.5%, 76.1% 2.6%, 74.1% 56.1%)" />
                        </div>
                    </div>
                    <h2 class="text-4xl mb-6 font-bold tracking-tight text-white">
                        {{ $t('contact.title') }}
                    </h2>
                    <img style="width: 400px" src="@/assets/img/contactus.jpg" />
                    <dl class="mt-10 space-y-1 text-lg leading-7 text-gray rounded-2xl bg-gray-800 px-8 py-5">
                        <div class="flex gap-x-4 mb-5">
                            <dt class="flex-none">
                                <span class="sr-only">Address</span>
                                <BuildingOffice2Icon class="h-7 w-6 text-gray" aria-hidden="true" />
                            </dt>
                            <dd>
                                Palais de la Bourse - Now Coworking
                                <br />
                                40 Place du Théâtre
                                <br />
                                59800 Lille , France
                            </dd>
                        </div>
                        <div class="flex gap-x-4">
                            <dt class="flex-none">
                                <span class="sr-only">{{ $t('contact.phone') }}</span>
                                <PhoneIcon class="h-7 w-6 text-gray" aria-hidden="true" />
                            </dt>
                            <dd><a class="hover:text-white" href="tel:+1 (555) 234-5678">+33 3 62 64 07 20</a></dd>
                        </div>
                        <div class="flex gap-x-4 mb-4">
                            <dt class="flex-none">
                                <span class="sr-only">{{ $t('contact.email') }}</span>
                                <EnvelopeIcon class="h-7 w-6 text-gray" aria-hidden="true" />
                            </dt>
                            <dd><a class="hover:text-white" href="mailto:hello@example.com">contact@bredlak.com</a></dd>
                        </div>
                    </dl>
                    <dl class="mt-2 space-y-1 text-lg leading-7 text-gray rounded-2xl bg-gray-800 px-8 py-5">
                        <div class="flex gap-x-4">
                            <dt class="flex-none">
                                {{ $t('contact.company_registrations') }}:
                            </dt>
                        </div>
                        <div class="flex gap-x-4 mt-1">
                            <dt class="flex-none">
                                <span>SIRET:</span>
                            </dt>
                            <dd><a class="hover:text-white" href="mailto:hello@example.com">{{ $t('contact.siret') }}</a>
                            </dd>
                        </div>
                        <div class="flex gap-x-4 mt-1">
                            <dt class="flex-none">
                                <span>EORI Number:</span>
                            </dt>
                            <dd><a class="hover:text-white" href="mailto:hello@example.com">{{ $t('contact.eori_number')
                            }}</a>
                            </dd>
                        </div>
                        <div class="flex gap-x-4">
                            <dt class="flex-none">
                                {{ $t('contact.copyright') }}:
                            </dt>
                        </div>
                    </dl>
                </div>
            </div>
            <form action="#" @submit.prevent="sendMessage" class="px-6 pb-24 mt-32 pt-20 sm:pb-32 lg:px-8 lg:py-48">
                <div class="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
                    <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                        <div class="sm:col-span-2">
                            <label for="name" class="block text-lg font-semibold leading-6 text-white">
                                {{ $t('contact.name') }} *
                            </label>
                            <div class="mt-2.5">
                                <input type="name" v-model="name" name="name" id="name" autocomplete="name"
                                    class="block w-full rounded-md border-0 px-3.5 py-2 text-dark ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-yellow-500 sm:text-sm sm:leading-6" />
                            </div>
                        </div>
                        <div class="sm:col-span-2">
                            <label for="email" class="block text-lg font-semibold leading-6 text-white">
                                {{ $t('contact.email') }} *
                            </label>
                            <div class="mt-2.5">
                                <input type="email" v-model="email" name="email" id="email" autocomplete="email"
                                    class="block w-full rounded-md border-0 px-3.5 py-2 text-dark ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-yellow-500 sm:text-sm sm:leading-6" />
                            </div>
                        </div>
                        <div class="sm:col-span-2">
                            <label for="phone-number" class="block text-lg font-semibold leading-6 text-white">
                                {{ $t('contact.phone') }} *
                            </label>
                            <div class="mt-2.5">
                                <input type="tel" v-model="phone" name="phone-number" id="phone-number" autocomplete="tel"
                                    class="block w-full rounded-md border-0 px-3.5 py-2 text-dark ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-yellow-500 sm:text-sm sm:leading-6" />
                            </div>
                        </div>
                        <div class="sm:col-span-2">
                            <label for="message" class="block text-lg font-semibold leading-6 text-white">
                                {{ $t('contact.message') }} *
                            </label>
                            <div class="mt-2.5">
                                <textarea v-model="message" name="message" id="message" rows="4"
                                    class="block w-full rounded-md border-0 px-3.5 py-2 text-dark ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-yellow-500 sm:text-sm sm:leading-6" />
                            </div>
                        </div>
                    </div>
                    <div class="mt-8 flex justify-center">
                        <Turnstile :sitekey="sitekey" @verify="verify" />
                    </div>
                    <div class="mt-8 flex justify-center">
                        <button v-if="!error && !success" type="submit"
                            class="rounded-md text-center bg-yellow-500 px-3.5 py-2.5 text-sm font-semibold text-white hover:bg-yellow-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500">
                            <span v-if="!loading">{{ $t('contact.send') }}</span>
                            <span v-else class="mx-auto">
                                <svg aria-hidden="true"
                                    class="w-8 h-8 mr-2 mx-auto text-gray-200 animate-spin dark:text-gray-600 fill-yellow-600"
                                    viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"
                                    style="margin: 0 auto">
                                    <path
                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                        fill="currentColor" />
                                    <path
                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                        fill="currentFill" />
                                </svg>
                                <span class="sr-only">Loading...</span>
                            </span>
                        </button>
                    </div>
                    <div class="rounded-md bg-green-50 p-4" v-if="success">
                        <div class="flex">
                            <div class="flex-shrink-0">
                                <CheckCircleIcon class="h-5 w-5 text-green-400" aria-hidden="true" />
                            </div>
                            <div class="ml-3">
                                <h3 class="text-sm font-medium text-green-800">{{ $t('contact.success') }}</h3>
                            </div>
                        </div>
                    </div>
                    <div class="rounded-md bg-red-50 p-4" v-if="error">
                        <div class="flex">
                            <div class="flex-shrink-0">
                                <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
                            </div>
                            <div class="ml-3">
                                <h3 class="text-sm font-medium text-red-800">{{ $t('contact.error') }}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
  
<script setup>
/* eslint-disable */
import { BuildingOffice2Icon, EnvelopeIcon, PhoneIcon, CheckCircleIcon, XCircleIcon } from '@heroicons/vue/24/outline'
import { ref } from 'vue';
import axios from "axios"
import Turnstile from 'cfturnstile-vue3'


const success = ref(false)
const error = ref(false)
const loading = ref(false)
const name = ref("")
const email = ref("")
const phone = ref("")
const message = ref("")
const sitekey = "0x4AAAAAAAIBcgjJcXwTwEPt"
let turnstile_token = null

const verify = async (token) => {
    turnstile_token = token;
};

const sendMessage = async () => {
    loading.value = true
    const form = {
        name: name.value,
        email: email.value,
        phone: phone.value,
        message: message.value,
        turnstile_token
    }

    try {
        // const { data } = await axios.post("https://bredlak-mail.teamlock.workers.dev/", form)
        const { data } = await axios.post("https://relaymail.teamlockapp.workers.dev/", form)
        console.log(data)
        success.value = true
    } catch (err) {
        console.error(err)
        error.value = true
    } finally {
        loading.value = false
    }
    setTimeout(() => {
        success.value = false
        error.value = false
    }, 2000);
}
</script>